/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'filetype-bmp': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M14 4.5V14a2 2 0 01-2 2v-1a1 1 0 001-1V4.5h-2A1.5 1.5 0 019.5 3V1H4a1 1 0 00-1 1v9H2V2a2 2 0 012-2h5.5zM0 15.849h1.664q.408 0 .72-.132.315-.135.493-.386.18-.253.179-.61a1 1 0 00-.123-.51.85.85 0 00-.323-.325 1.1 1.1 0 00-.445-.14v-.036a1 1 0 00.352-.16.8.8 0 00.243-.294.9.9 0 00.09-.422q0-.463-.322-.723-.322-.261-.858-.261H0zm.785-3.404h.7q.28 0 .431.14.155.138.155.384a.5.5 0 01-.082.296.5.5 0 01-.249.185 1.2 1.2 0 01-.433.064H.785zm0 1.62h.75q.231 0 .393.073a.5.5 0 01.24.211.6.6 0 01.082.325q0 .284-.205.434-.205.146-.671.146H.785zm3.474 1.784v-2.66h.038l.952 2.16h.515l.947-2.16h.038v2.66h.715V11.85h-.8l-1.14 2.596h-.026l-1.14-2.596h-.805v3.999zm3.918-3.999h1.6q.434 0 .732.179.302.176.46.477.159.302.159.677t-.162.677q-.158.299-.462.474a1.45 1.45 0 01-.733.173h-.803v1.342h-.79zm2.06 1.714a.8.8 0 00.085-.381q0-.34-.185-.521-.185-.182-.512-.182h-.66v1.406h.66a.8.8 0 00.375-.082.57.57 0 00.237-.24"/>',
    },
});
